<template>
  <div class="p-10">
    <div class="text-center pb-10 text-gray-600 text-lg">
      {{ company.name }}
    </div>
    <el-form :model="company" label-width="200px">
      <el-form-item label="公司统一信用代码">
        <el-input v-model="company.ucc"></el-input>
      </el-form-item>
      <el-form-item label="公司税务密码">
        <el-input v-model="company.etax_password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
export default defineComponent({
  name: "CompanyEdit",
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const uid = route.params.uid;
    const company = ref({});

    const onSubmit = async () => {
      const { ucc, etax_password } = company.value;
      await store.dispatch("company/update", {
        uid,
        patch: { ucc: ucc.trim(), etax_password: etax_password.trim() },
      });
      router.go(-1);
    };

    const cancel = () => router.go(-1);

    onMounted(async () => {
      const res = await store.dispatch("company/get", uid);
      company.value = res.data;
    });
    return {
      company,
      onSubmit,
      cancel,
    };
  },
});
</script>
